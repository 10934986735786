import {Component} from '@angular/core';
import {Stabili} from '../../model/stabili';
import {HttpClient} from '@angular/common/http';
import {Message} from '../../model/message';
import {PrivacyService} from '../../services/privacy.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'click-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  message = false;
  stabileAttivo: Stabili;

  constructor(
    private http: HttpClient,
    private api: PrivacyService,
    private toast: ToastrService
  ) {
  }


  cercaStabile(codiceFiscale: string) {
    this.api.getDatiStabile(codiceFiscale)
      .subscribe((result: Stabili) => {
        this.message = false;
        this.stabileAttivo = result;
        let el = document.getElementById('reservation');
        el.scrollIntoView();
        // console.log(this.stabileAttivo);
      }, (error: Message) => {
        this.stabileAttivo = null;
        this.toast.warning("Condominio non trovato", "", {
          positionClass: 'toast-bottom-right'
        });
        // this.message = true;
        // console.warn(error);
      });
  }
}
