import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Stabili} from '../../model/stabili';
import {PrivacyService} from '../../services/privacy.service';
import {Message} from '../../model/message';
import {WscommonService} from '../../services/wscommon.service';

@Component({
  selector: 'click-stabile',
  templateUrl: './stabile.component.html',
  styleUrls: ['./stabile.component.css']
})
export class StabileComponent {

  @Input() stabile: Stabili;
  disable = false;
  message: Message

  constructor(
    private http: HttpClient,
    private api: PrivacyService,
    private apiWs: WscommonService
  ) {
  }

  stampaInformativa(codiceFiscale: string) {
    this.disable = true;
    this.message = {"error_number": 200, "title": "", "description": ""};
    this.api.getPrivacyStabile(codiceFiscale)
      .subscribe((result) => {
        const data = {"html": result.text, "orientation": "verical", "size": "A4"};
        this.apiWs.getHtmlPrivacy(data)
          .subscribe((wsResult) => {
            const blob = this.converBase64toBlob(wsResult.pdf, 'application/pdf');
            window.open(URL.createObjectURL(blob));
          }, (error) => {
            this.message.error_number = error.status;
            this.message.title = "ERRORE NEL CARICAMENTO DEL DOCUMENTO";
            this.message.description = "";
          });
      }, (error: Message) => {
        console.warn(error);
      });

    this.disable = false;
  }


  converBase64toBlob(content, contentType) {
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  }

}


