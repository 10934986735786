<section class="section" id="about">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="left-text-content">
          <div class="section-heading" style="background-color: #fa5949;">
            <h2 class="text-white text-uppercase px-1 px-sm-2" style="font-weight: 600;letter-spacing: 2px;"><span>La privacy nel condominio</span></h2>
          </div>
          <p style="font-size: 16px; line-height: 24px;">
            <strong class="text-uppercase">Con <span style="color: #fa5949;">privacyClick.it</span> l’informativa viene resa disponibile ad ogni condomino in
              modo trasparente ed efficace</strong>
          </p>
          <p style="font-size: 16px; line-height: 24px;">
            <strong>Il condominio rappresenta il luogo in cui un gruppo di persone condivide beni e servizi comuni, essenziali o
              utili all’utilizzo dei beni individuali di ciascuno</strong>.<br>
            Ciò comporta la necessità di affidare la gestione dei
            beni e servizi ad un soggetto che si impegni a mantenerli in funzione: l’amministratore di condominio. Per
            eseguire il suo mandato nel pieno rispetto della normativa, l’amministratore di condominio inevitabilmente
            viene a conoscenza dei dati personali dei singoli condomini.
          </p>
          <p style="font-size: 16px; line-height: 24px;">
            <strong>Tali dati</strong>, siccome idonei a rivelare
            informazioni importanti e riservate anche relative alla sfera privata delle persone, <strong>devono essere
            opportunamente trattati e conservati</strong>. Con l’informativa della privacy l’amministratore descrive
            all’interessato le finalità e le modalità del trattamento dei suoi dati personali, nell’ambito della
            gestione del condominio.
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12 mt-3">
        <div class="right-content">
          <div class="thumb">
            <img src="./assets/images/img1_s.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <click-link></click-link>
  </div>
</section>
