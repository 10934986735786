<section class="testimonials text-center bg-light">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="testimonial-item mx-auto mb-5 mb-lg-0">
          <a href="http://www.garanteprivacy.it/" target="_blank">
            <img class="img-fluid mb-3" src="../../../assets/images/link-1.jpg" alt="">
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mx-auto mb-5 mb-lg-0">
          <a href="https://www.clicksrl.eu/" target="_blank">
            <img class="img-fluid mb-3 w-25" src="../../../assets/images/link-2.jpg" alt="">
          </a>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="testimonial-item mx-auto mb-5 mb-lg-0">
          <a href="http://www.agenziaentrate.gov.it/" target="_blank">
            <img class="img-fluid mb-3" src="../../../assets/images/link-3.jpg" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
