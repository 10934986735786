import { Component } from '@angular/core';

@Component({
  selector: 'click-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  toggleMenu(hide) {
    var x = document.getElementById("myLinks");
    if (x.style.display === "block" || hide) {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }
}
