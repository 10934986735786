import {Component, EventEmitter, Output} from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'click-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {

  @Output() codiceFiscale = new EventEmitter<string>();

  constructor() {
  }

  cercaCodiceFiscale(form: NgForm) {
    this.codiceFiscale.emit(form.value.cf);
    form.resetForm();
  }
}
