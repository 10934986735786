<div class="contact-form">
  <form #f="ngForm" (submit)="cercaCodiceFiscale(f)">
    <fieldset>
      <input name="cf" type="text" id="cf" placeholder="Codice fiscale condominio" [ngModel] required minlength="11" maxlength="16">
      <button type="submit" id="form-submit" class="main-button-icon" [disabled]="!f.valid">
        <h6>Ricerca</h6>
      </button>
    </fieldset>
  </form>
</div>
