<div>
  <!--start navigation bar-->
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <a routerLink="home" class="logo">
              <img src="assets/images/logo.png">
            </a>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul class="nav" id="myLinks">
              <li class="scroll-to-section"><a routerLink="home" routerLinkActive="active" (click)="toggleMenu(true)" >Home</a></li>
              <li class="scroll-to-section"><a routerLink="servizi" routerLinkActive="active" (click)="toggleMenu(true)">La privacy nel condominio</a></li>
            </ul>
            <a class='menu-trigger' (click)="toggleMenu(false)">
              <span>Menu</span>
            </a>

            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!--stop navigation bar-->

  <router-outlet></router-outlet>

</div>
