import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WscommonService {

  BASEURL = "https://ws-common.softwareclick.it:8000";
  TOKEN = "ObKcDoeZVSu8hL8inF3J7G1H2LKUwHCgg8Ug9iT3HoprRWKDgEWxh1umkJL1";

  constructor(
    private injector: Injector
  ) {
  }


  public getHtmlPrivacy(obj: object): any {
    const serviceHeaders = this.getHeaders();
    const options = {headers: serviceHeaders};
    return this.injector.get(HttpClient).post(`${this.BASEURL}/api/v1/convertToPdf`, obj, options);
  }


  private getHeaders(): HttpHeaders {
    const header = new HttpHeaders({
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'Authorization': 'Bearer ' + this.TOKEN,
    });

    return header;
  }

}
