<section class="section m-0" id="reservation" style="min-height: 65vh;">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 align-self-center">
        <div class="left-text-content">
          <div *ngIf="!stabile" class="text-white text-uppercase" style="font-weight: 600;letter-spacing: 3px;">
            <div class="section-heading">
              <h1 class="">Privacy<span style="background-color: #fa5949;">Click</span></h1>
            </div>
            <h6>
              lo strumento con cui il tuo amministratore di condominio con la massima
              trasparenza e semplicità rende fruibile via web l’informativa privacy del tuo condominio, assolvendo a
              quanto previsto dal GDPR.
            </h6>
            <h5 class="mt-3">
              Inserisci il codice fiscale del tuo condominio per visualizzare l’informativa.
            </h5>
          </div>
          <div class="row" *ngIf="stabile">
            <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <div class="phone">
                <i class="fa fa-home"></i>
                <div class="contact-form">
                  <div class="row mt-2">
                    <div class="col-sm-12 text-center">
                      <strong>CONDOMINIO</strong><br>
                      {{stabile.ragione_sociale_stabile}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-sm-12 text-center">
                      <strong>INDIRIZZO</strong><br>
                      {{stabile.indirizzo_stabile}}</div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-sm-12 text-center">
                      <strong>CODICE FISCALE</strong><br>
                      {{stabile.codice_fiscale_stabile}}</div>
                  </div>
                  <fieldset style="padding: 0 25px">
                    <button type="submit" id="form-submit" class="main-button-icon" style="margin-top: 25px;"
                            (click)="stampaInformativa(stabile.codice_fiscale_stabile)"
                            [disabled]="disable"
                    >
                      <h6 class="mt-2"><span class="fa fa-download fa-lg"></span> INFORMATIVA PRIVACY</h6>
                    </button>
                  </fieldset>
                  <div class="row mt-2">
                    <div class="col-sm-12 text-center" *ngIf="message">
                      <strong>{{message.title}}</strong>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
