<div id="top">
  <div class="container-fluid" style="min-height: 70vh;">
    <div class="row">
      <div class="col-lg-4" style="min-height: 65vh;">
        <div class="left-content">
          <div class="inner-content text-center mt-2">
            <h6>Inserisci il codice fiscale del condominio</h6>
            <div class="main-white-button scroll-to-section">
              <click-search (codiceFiscale)="cercaStabile($event)"></click-search>
            </div>
          </div>
          <h6 class="m-0 pt-4 text-center" *ngIf="message">Condominio non trovato</h6>
        </div>
      </div>
      <div class="col-lg-8">
        <a name="stabile"></a>
        <div class="main-banner header-text">
          <div class="Modern-Slider">
            <!-- Item -->
            <div class="item">
              <div class="img-fill">
                <click-stabile [stabile]="stabileAttivo"></click-stabile>
              </div>
            </div>
            <!-- // Item -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
